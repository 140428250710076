.ErrorMessage {
  background: #faded6;
  color: #e65932;
}

.submitErrorMsg {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
}

.submitSuccessMsg {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
}
