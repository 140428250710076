.UsersTable {
  margin: 2rem 0;
  width: 100%;
  border-collapse: collapse;
}

.UsersTable thead {
  text-align: left;
}

.UsersTable th {
  border-bottom: 2px solid #001457;
}

.UsersTable th button {
  display: block;
  width: 100%;
  border: 0;
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin: 0;
  text-align: left;
  outline: none;
  padding-bottom: 0.5rem;
}
