*, *:before, *:after {
 box-sizing: inherit;
 font-size: 100%;
}

html {
  font-family: "Helvetica Neue LT Std", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #001457;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: #eee;
  margin: 0;
  padding: 0;
  height: 100%;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: #001457;
  outline: none;
  cursor: pointer;
}

a:focus {
  outline: none;
}

a:visited {
  /* color: #001457; */
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
}

.completed {
  border-radius: 1rem;
}

.correct {
  color: #50d400;
  background: #dcf6cc;
}

.correct-color {
  color: #50d400;
}
input[type="radio"].correct-color {
  border-color: #50d400;
}

input[type="radio"].incorrect-color {
  border-color: #e65932;
}

input[type="radio"]:checked.correct-color {
  box-shadow: 0 0 0 2px #50d400;
  border-color: #fff;
  background-color: #50d400;
}

input[type="radio"]:checked.incorrect-color {
  box-shadow: 0 0 0 2px #e65932;
  border-color: #fff;
  background-color: #e65932;
}

.incorrect {
  background: #faded6;
  color: #e65932;
}

.incorrect-color {
  color: #e65932;
}

.gray {
  color: #666;
}

.graybg {
  background: #eee;
}

.chevron::before {
	border-style: solid;
	border-width: 0.125em 0.125em 0 0;
	content: '';
	display: inline-block;
	height: 0.5em;
	width: 0.5em;
	left: 0.15em;
	position: relative;
	top: 0.15em;
	transform: rotate(-45deg);
	vertical-align: top;
}

.chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}

.chevron.down:before {
	left: 0;
	transform: rotate(135deg);
}

.material-icons-inline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.material-icons-small {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1.75;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  vertical-align: middle;
  direction: ltr;
  margin-right: .25rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.material-icons-xs {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  vertical-align: middle;
  direction: ltr;
  margin-right: .25rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

td { overflow: hidden; text-overflow: ellipsis;}

.ReactModal__Overlay {
  z-index: 1000;
}
