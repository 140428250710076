.QuizSection {}

.QuizSection-header {
  top: 0;
  left: 0;
  z-index: 2;
}

.review-title {
  margin-top: -8px;
}

.BackAll-button {
  background-color: #001457;
  min-width: 290px;
}

.QuizSection-title {}

.QuizSection-questions {}
