.SplashScreen {
  background: #001457;
  height: 100vh;
  min-height: 100%;
  background-size: cover;
  background-position: 40% center;
  overflow: hidden;
}

.SplashScreen-loginLink {
  color: #fff;
  font-weight: bold;
  display: inline-block;
}

.Challenge-button {
  background-color: #a19918;
  min-width: 290px;
}

input::placeholder {
  font-weight: bold;
  color: #ddd;
}
