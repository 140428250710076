.InputField {
  margin-bottom: 1rem;
}

.InputField label {
  display: block;
  margin-bottom: 1rem;
}

.InputField input {
  font: inherit;
  padding: 0.5rem 0;
  border: 0;
  width: 100%;
  display: block;
  border-bottom: 2px solid #ddd;
  outline: none;
}

.InputField input:disabled {
  opacity: 0.4;
}


.InputField input[type="text"]:focus {
  border-bottom-color: #001457;
  color: #001457;
  font-weight: bold;
}

.InputField-error {
  color: #e65932;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
  border: none;
  border-bottom: 2px solid #eee;
  color: #001457;
  font-weight: 600;
  height: 38px;
  padding: 13px 0px 13px 0px;
  width: 100%;
}

[type=search]:focus {
  outline: none;
  border-bottom: 2px solid #001457;
}
